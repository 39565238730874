var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "Users", fluid: "", tag: "section" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "v-card--material true px-5 py-3" },
                [
                  _c("card-heading", {
                    attrs: { title: "Users", color: "secondary" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return undefined
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", {
                            staticClass: "ml-auto",
                            attrs: { cols: "6" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      "sort-by": "last_name",
                      "sort-desc": true,
                      "must-sort": "",
                      headers: _vm.headers,
                      items: _vm.list.data.data,
                      search: _vm.search,
                      "item-class": _vm.itemClass,
                      options: _vm.options,
                      "server-items-length": _vm.total_records,
                      "footer-props": _vm.footerProps,
                      loading: _vm.list.isLoading,
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                      "click:row": _vm.handleClick,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "top",
                          fn: function ({ pagination }) {
                            return [
                              _c(
                                "v-data-footer",
                                _vm._b(
                                  {
                                    staticClass: "anton",
                                    attrs: {
                                      options: _vm.options,
                                      pagination: pagination,
                                    },
                                    on: {
                                      "update:options": function ($event) {
                                        _vm.options = $event
                                      },
                                    },
                                  },
                                  "v-data-footer",
                                  _vm.footerProps,
                                  false
                                )
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.last_name`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(item.last_name) +
                                  " " +
                                  _vm._s(item.first_name) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }